<template>

<app-wrapper class="timeline">

	<app-wrapper :content="true" class="timeline-content">

		<div class="timeline-item" v-for="(item, index) in $state.page.timeline" :key="index">

			<div class="timeline-item-year">{{ item.year }}</div>
			<div class="timeline-item-text">{{ item.text }}</div>

		</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.timeline {
	background-color: #1A2046;
}

.timeline-content {
	padding: 100px 0px;
}

.is-mobile .timeline-content {
	padding: 32px 0px;
}

.timeline-content:after {
	content: '';
	position: absolute;
	left: 219px;
	top: 0px;
	width: 1px;
	bottom: 0px;
	background-color: #fff;
}

.is-mobile .timeline-content:after {
	left: 80px;
}

.timeline-item {
	display: flex;
}

.timeline-item-year {
	text-align: right;
	color: #fff;
	font-weight: 600;
	font-size: 22px;
	padding: 14px 20px 14px 0px;
	flex-shrink: 0;
	width: 200px;
}

.is-mobile .timeline-item-year {
	width: 80px;
	font-size: 14px;
	line-height: 20px;
}

.timeline-item-text {
	font-size: 16px;
	font-weight: 400;
	flex-grow: 1;
	padding-top: 10px;
	color: #fff;
	line-height: 28px;
	padding-left: 52px;
	background-position: 4px 15px;
	background-repeat: no-repeat;
	background-size: 22px 18px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAkCAMAAADFCSheAAAAOVBMVEUAAAD/LQf/MAD/LQj/KgX/LQf/LQf/LQb/LAj/LQf/Lgf/LQb/MAj/Lgf/LQf/LQf/LQf/Lgf/LQcOtzQBAAAAEnRSTlMA8BBgMMCAoEDQ4FAgcN+PsG+xiS7MAAABQElEQVQ4y5WV23KEIAyGIZAA6uou7/+wLYfprxJsmxsT8k0mp4lGE38w22yZxZtn2YLNECs0R0mA/oZ7oJCkJ+N6sLBGMhRdz8iuCvuqrsWdnxruJnHlVkSosb3G2kkMuylvflZJeGYhUnzxxuKBonxOXv728a2/7meIucpB/SEWy6usq1ZPC6HlyiIMaGSZFJaqlcJ7yciUig72hTF2i8KpZm4q7dc2JqTHzQGY0q3lxezjWpGH1BBcnLu5wl31Za2aGmpV3iqRCX3hcxqdZlJyfiNManDPOtF5EVZoaB2ZK40+y7HXby+1qwPthgli3FMaLBYJNHzc2LANK4pGYsu2l4ij2fKDHsX1jRrpVWcXMgqdj+EUoLqR3p2BfHBkpufLld2N7m2bHfXDuORRFv+Pk/vnY74ImWfxwpyV38QXWIskwjEmUtMAAAAASUVORK5CYII=');
}

.is-mobile .timeline-item-text {
	font-size: 14px;
	padding-top: 13px;
	line-height: 20px;
	padding-right: 20px;
}

</style>
